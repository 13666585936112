// import { useState } from "react";
import "./App.css";
import { Header } from "../Header/Header";
import { TetrisApp } from "../TetrisApp/TetrisApp";
import { TicTacToe } from "../TicTacToe/TicTacToe";

function App() {
  return (
    <>
      <Header />
      <main>
        <div className="infoContainer">
          <h1>Bienvenido a <br /> Impresos & Suministros SAS</h1>
          <p>
            Somos una empresa de marketing y publicidad, donde puedes encontrar
            todo tipo de productos y servicios para tu negocio, emprendimiento o
            desarrollo personal. <br />
            <a
              href="https://wa.me/573153480277?text=Hola%21%20Estoy%20interesado%20en%20sus%20servicios"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Escribenos para más información!</button>
            </a>
            <br /> En estos momentos nuestra pagina web se encuentra en
            construccion pero, mientras trabajamos en ella disfruta jugando un
            poco!
          </p>
        </div>
        <TetrisApp />
        <TicTacToe />
      </main>
      <footer>
        <p>
          © 2024 | Impresos & Suministros SAS <br /> Desde 2012 imprimiendo
          calidad <br />
          Todos los derechos reservados{" "}
        </p>
      </footer>
    </>
  );
}

export { App };
