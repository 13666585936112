import "./TetrisApp.css";
import React from "react";
import Tetris from "react-tetris";

const TetrisApp = () => (
    <div className="container">
      {/* <h1>Tetris</h1> */}
      <Tetris
        keyboardControls={{
          // Default values shown here. These will be used if no
          // `keyboardControls` prop is provided.
          down: "MOVE_DOWN",
          left: "MOVE_LEFT",
          right: "MOVE_RIGHT",
          space: "HARD_DROP",
          z: "FLIP_COUNTERCLOCKWISE",
          x: "FLIP_CLOCKWISE",
          up: "FLIP_CLOCKWISE",
          p: "TOGGLE_PAUSE",
          c: "HOLD",
          shift: "HOLD",
        }}
      >
        {({
          HeldPiece,
          Gameboard,
          PieceQueue,
          points,
          linesCleared,
          state,
          controller,
        }) => (
          <>
            <div className="stats">
              <p>Puntos: {points}</p>
              <p>Lineas: {linesCleared}</p>
          </div>
          <div className="mainDiv">
            <div className="HeldPiece"><HeldPiece /></div>
            <div className="Gameboard"><Gameboard /></div>
            <div className="PieceQueue"><PieceQueue /></div>
          </div>
          {state === "LOST" && (
            <div className="game-over-container">
              <div className="game-over">
                <h2>Game Over</h2>
                <button onClick={controller.restart}>Jugar</button>
              </div>
            </div>
          )}
          </>
        )}
      </Tetris>
    </div>
    );

export { TetrisApp }