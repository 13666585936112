import "./Social.css";

const links = [
  {
    id: 1,
    url: "https://www.instagram.com/ig.impresosysuministros/",
    class: "fa-brands fa-instagram",
  },
  // {
  //   id: 2,
  //   url: "https://www.facebook.com/?locale=es_LA",
  //   class: "fa-brands fa-square-facebook",
  // },
  // {
  //   id: 3,
  //   url: "https://www.youtube.com/",
  //   class: "fa-brands fa-youtube",
  // },
  {
    id: 4,
    url: "https://wa.me/573153480277?text=Hola%21%20Estoy%20interesado%20en%20sus%20servicios",
    class: "fa-brands fa-whatsapp",
  },
];

function Social() {
  const listItems = links.map((link) => (
    <li key={link.id}>
      <a href={link.url} target="_blank" rel="noreferrer">
        <i class={link.class}></i>
      </a>
    </li>
  ));

  return <>{listItems}</>;
}

export { Social };
