import "./Header.css";
import logo from "../logo.png";
import { Social } from "../Header/Social/Social";

function Header() {
  return (
    <header className="App-header">
      <nav>
        <img src={logo} className="App-logo" alt="logo" />
        <div className="redes">
          <p>Contactanos en:</p>
          <ul>
            <Social />
          </ul>
        </div>
      </nav>
    </header>
  );
}

export { Header };
